import type {
  ErrorLogger,
  GraphQLErrorLogger,
} from '@seek/hirer-graphql-client/lib-types/types';

import type { ContextType as DatadogRumContextType } from '../useDatadogRum';

const mapRequestErrorLogger =
  (logger: DatadogRumContextType): ErrorLogger =>
  (thrownErr, operationName, metadata) => {
    // casts unknown while https://github.com/SEEK-Jobs/hirer-graphql-client/issues/64 is resolved
    const error =
      thrownErr && typeof thrownErr === 'object'
        ? (thrownErr as Error)
        : new Error('Unknown error');
    logger.logError(error.message, {
      ...metadata,
      operationName,
      activity: operationName,
      error,
    });
  };

const mapGraphQLErrorLogger =
  (logger: DatadogRumContextType): GraphQLErrorLogger =>
  (error, operationName, metadata) => {
    logger.logError(error.message, {
      ...metadata,
      operationName,
      activity: operationName,
      error: new Error(error.message),
    });
  };

export { mapRequestErrorLogger, mapGraphQLErrorLogger };
