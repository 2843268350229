import { Box, Loader } from 'braid-design-system';

import * as styles from './RouteLoader.css';

export const RouteLoader = () => (
  <Box className={styles.container}>
    <Box className={styles.loader}>
      <Loader />
    </Box>
  </Box>
);
