import type { DefaultOptions } from '@apollo/client/core/ApolloClient';
import hirerGraphClient, {
  type HirerGraphqlClientOptions,
} from '@seek/hirer-graphql-client';
import type { ApolloClientWithCache } from '@seek/hirer-graphql-client/lib-types/client/createClient';

let client: ApolloClientWithCache;

function createClient(
  options: Omit<
    HirerGraphqlClientOptions,
    'batchOption' | 'defaultOptions' | 'logMetrics'
  >,
) {
  if (client) {
    return client;
  }

  const fetchPolicy = 'no-cache';
  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy,
    },
    watchQuery: {
      fetchPolicy,
    },
    mutate: {
      fetchPolicy,
    },
  };

  client = hirerGraphClient({
    batchOptions: {
      batchMax: 20,
      batchInterval: 20,
    },
    defaultOptions,
    // TODO: Leverage this to DD RUM
    logMetrics: () => {},
    ...options,
  });

  return client;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createClient;
