import { Box } from 'braid-design-system';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import type loadable from 'sku/@loadable/component';

import { IntercomConfiguration } from '../../../../setup/IntercomConfiguration';
import { selectors as routerSelectors } from '../../../../store/router';
import { selectors as userSelectors } from '../../../../store/user';
import type { RouteDefinition } from '../../../../types';
import JobDataContainer from '../../containers/JobDataContainer/JobDataContainer';
import MetadataContainer from '../../containers/MetadataContainer/MetadataContainer';
import SearchIdContainer from '../../containers/SearchIdContainer/SearchIdContainer';
import AppReadyIdentifier from '../AppReadyIdentifier/AppReadyIdentifier';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import StickyBanners from '../StickyBanners/StickyBanners';

import * as styles from './AppView.css';

interface Props {
  routes: {
    unrestricted: RouteDefinition[];
    restricted: RouteDefinition[];
    recommendedCandidates: {
      path: string;
      component: ReturnType<typeof loadable>;
    };
  };
}

export const TalentSearchHeaderRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isNotRootOrErrorRoute = useSelector(
    routerSelectors.isValidRouteSelector,
  );

  return (
    <Box background="body">
      <StickyBanners />
      <Header />

      <Box className={styles.scene} children={children} />
      {isNotRootOrErrorRoute && <Footer />}
    </Box>
  );
};

export function AppView({ routes }: Props) {
  const hasInitialUserData = useSelector(
    userSelectors.getInitialDataStateSelector,
  );

  return (
    <ErrorBoundary>
      <Switch>
        {routes.unrestricted.map(({ key, component: Component, ...route }) => (
          // @ts-expect-error Types of property 'path' are incompatible.
          <Route key={key} {...route}>
            <TalentSearchHeaderRoute>
              <Component />
            </TalentSearchHeaderRoute>
          </Route>
        ))}
        {/* @ts-expect-error Type 'unknown' is not a valid JSX element type. */}
        <PrivateRoute
          path={`/:language?${routes.recommendedCandidates.path}`}
          component={routes.recommendedCandidates.component}
          exact
        />
        {routes.restricted.map(({ key, component: Component, ...route }) => (
          // @ts-expect-error Type 'unknown' is not a valid JSX element type.
          <PrivateRoute
            key={key}
            {...route}
            render={() => (
              <TalentSearchHeaderRoute>
                <Component />
              </TalentSearchHeaderRoute>
            )}
          />
        ))}
      </Switch>

      {/* Data services and configs */}
      {hasInitialUserData && (
        <>
          <JobDataContainer />
          <MetadataContainer />
          <SearchIdContainer />
          <AppReadyIdentifier />
          <IntercomConfiguration />
        </>
      )}
    </ErrorBoundary>
  );
}
