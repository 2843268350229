import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type * as Types from '../../../types/generated';

const defaultOptions = {} as const;
export type GetTalentSearchJobByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  language?: Types.InputMaybe<Types.Scalars['LanguageCodeIso']['input']>;
}>;

export type GetTalentSearchJobByIdQuery = {
  talentSearchJobByIdV2?: {
    id: string;
    title: string;
    expiryDate: string;
    reference?: string | null;
    isUpgradable?: boolean | null;
    location: { countryCode?: string | null; description?: string | null };
    salary: { frequency: Types.SalaryFrequency };
  } | null;
};

export const GetTalentSearchJobByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchJobById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageCodeIso' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchJobByIdV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'language' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isUpgradable' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchJobByIdQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchJobByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetTalentSearchJobByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTalentSearchJobByIdQuery,
    GetTalentSearchJobByIdQueryVariables
  > &
    (
      | { variables: GetTalentSearchJobByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchJobByIdQuery,
    GetTalentSearchJobByIdQueryVariables
  >(GetTalentSearchJobByIdDocument, options);
}
export function useGetTalentSearchJobByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchJobByIdQuery,
    GetTalentSearchJobByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchJobByIdQuery,
    GetTalentSearchJobByIdQueryVariables
  >(GetTalentSearchJobByIdDocument, options);
}
export function useGetTalentSearchJobByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTalentSearchJobByIdQuery,
        GetTalentSearchJobByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTalentSearchJobByIdQuery,
    GetTalentSearchJobByIdQueryVariables
  >(GetTalentSearchJobByIdDocument, options);
}
export type GetTalentSearchJobByIdQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobByIdQuery
>;
export type GetTalentSearchJobByIdLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobByIdLazyQuery
>;
export type GetTalentSearchJobByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobByIdSuspenseQuery
>;
export type GetTalentSearchJobByIdQueryResult = Apollo.QueryResult<
  GetTalentSearchJobByIdQuery,
  GetTalentSearchJobByIdQueryVariables
>;
