import type { useRoutes } from 'src/hooks/useRoutes';

import { selectors as profilesSelectors } from '../../store/profiles';
import { selectors as routerSelectors } from '../../store/router';
import { selectors as searchesSelectors } from '../../store/searches';
import { selectors as settingsSelectors } from '../../store/settings';
import { selectors as userSelectors } from '../../store/user';
import {
  RouteKeys,
  PoolChannels,
  ErrorTypes,
  type RootState,
  type TalentSearchJobV2,
  type MelwaysLanguage,
  type EmployerSiteName,
  type ProfileV2,
} from '../../types';
import { DATAPLATFORM_KEY } from '../dataPlatform';
import dataPlatformSelectors from '../dataPlatform/selectors';
import {
  Source,
  TabLocations,
  EventName as DataPlatformEventName,
  EventName,
} from '../dataPlatform/types';
import getDefaultProfileFields from '../dataPlatform/utils/getDefaultProfileFields';
import { TEALIUM_KEY } from '../tealium';
import tealiumSelectors from '../tealium/selectors';
import { Pooling, UtagActions } from '../tealium/types';
import getCvPreviewStatusForProfile from '../tealium/utils/getCvPreviewStatusForProfile';
import getGeneralTemplateValues from '../tealium/utils/getGeneralTemplateValues';
import { getProfileTrackingFields } from '../tealium/utils/getProfileTrackingFields';
import { getSearchResultTrackingFields } from '../tealium/utils/getSearchResultTrackingFields';

const channelToEventNameMapper: Record<string, string> = {
  [PoolChannels.Pool]: Pooling.PoolViewed,
};

const channelToEventNameMapperForDP: Record<string, string> = {
  [PoolChannels.Pool]: EventName.TSPoolViewed,
};

const getJobMetaData = ({
  id: jobId,
}: TalentSearchJobV2): {
  jobId: string;
} => ({
  jobId: String(jobId),
});

const actions = {
  [RouteKeys.Profile]: ({
    profile,
    similarCandidateTotal,
  }: {
    profile: ProfileV2;
    similarCandidateTotal: number;
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const seedProfileId =
          routerSelectors.getSeedProfileIdFromQueryStringSelector(snapshot);
        const availableConnectionTypes =
          tealiumSelectors.getConnectionTypesAvailableSelector(
            snapshot,
            profile,
          );
        const isCvPreviewAvailable =
          getCvPreviewStatusForProfile(profile) === 'cv+preview';
        const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
        return {
          action: UtagActions.View,
          value: {
            ...(jobId && { jobId: jobId.toString() }),
            eventName: 'candidate_profile_displayed',
            availableConnectionTypes,
            isCvPreviewAvailable,
            similarCandidateTotal,
            seedProfileId,
            ...getProfileTrackingFields(profile),
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const defaultProfileFields = getDefaultProfileFields(profile);

        const connectionId = null;

        const jobId = routerSelectors.getActiveJobIdSelector(snapshot);
        const seekAdvertiserId =
          userSelectors.getAdvertiserIdSelector(snapshot);
        const userId = userSelectors.getUserIdSelector(snapshot);
        const userSessionId =
          settingsSelectors.getUserSessionIdSelector(snapshot);
        const organisationId = userSelectors.getCompanyIdSelector(snapshot);
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);
        const fromProfilePage =
          dataPlatformSelectors.getFromProfilePageSelector(snapshot);
        const site = dataPlatformSelectors.getSiteValueSelector(snapshot);
        const profilePosition = profilesSelectors.getProfilePositionSelector(
          snapshot,
          profile,
        );
        const similarCandidatesSearchId =
          searchesSelectors.getSimilarCandidatesSearchIdIfSeedProfileIdExistsSelector(
            snapshot,
          );
        const tags = userSelectors.getHirerAccountTagsSelector(snapshot);
        const productType = userSelectors.getProducTypeForMetrics(
          snapshot,
          true,
        );

        return {
          value: {
            eventName: DataPlatformEventName.TSProfileOpened,
            metrics: {
              source: Source.Site,
              site,
              resultListTab: TabLocations.Results,
              jobId,
              seekAdvertiserId,
              userId,
              userSessionId,
              organisationId,
              searchId,
              similarCandidatesSearchId,
              productType,
              profile: {
                ...defaultProfileFields,
                profilePosition: profilePosition ?? 0,
                fromProfilePage,
              },
              connectionId,
              tags: tags
                ? { ...tags, testRecord: tags.testRecord === 'true' }
                : undefined,
            },
          },
        };
      },
    },
  ],
  [RouteKeys.Error]: ({ errorType }: { errorType: ErrorTypes | null }) => [
    {
      key: TEALIUM_KEY,
      getValue: () => {
        const type = errorType || ErrorTypes.UNKNOWN;
        return {
          action: UtagActions.View,
          value: {
            currentPage: `error ${type}`,
            eventName: 'error_page_displayed',
          },
        };
      },
    },
  ],
  [RouteKeys.Pooling]: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => ({
        action: UtagActions.View,
        value: {
          eventName: Pooling.PoolsViewed,
        },
      }),
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: () => ({
        value: {
          eventName: EventName.TSPoolsViewed,
        },
      }),
    },
  ],
  [RouteKeys.PoolingChannel]: (
    poolId: string,
    isPoolOwner: boolean,
    poolProfileCount: number,
  ) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const channel = routerSelectors.getChannelFromParams(snapshot);
        return {
          action: UtagActions.View,
          value: {
            eventName: channelToEventNameMapper[channel],
            searchType: 'talent-pool',
            poolId,
            isPoolOwner,
            poolProfileCount,
          },
        };
      },
    },
    {
      key: DATAPLATFORM_KEY,
      getValue: (snapshot: RootState) => {
        const channel = routerSelectors.getChannelFromParams(snapshot);
        return {
          value: {
            eventName: channelToEventNameMapperForDP[channel],
            metrics: {
              searchType: 'talent-pool',
              poolId,
              isPoolOwner,
              poolProfileCount,
            },
          },
        };
      },
    },
  ],
  [RouteKeys.Search]: () => [
    {
      key: TEALIUM_KEY,
      getValue: () => ({
        action: UtagActions.View,
        value: {
          eventName: `search_displayed`,
        },
      }),
    },
  ],
  [RouteKeys.SearchResults]: ({
    profiles,
    profilesCount,
    job,
    site,
    melwaysLanguage,
    routes,
  }: {
    profiles: ProfileV2[];
    profilesCount: number;
    job?: TalentSearchJobV2;
    site: EmployerSiteName;
    melwaysLanguage: MelwaysLanguage;
    routes: ReturnType<typeof useRoutes>['routes'];
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const pageMetaData = getGeneralTemplateValues(
          snapshot,
          site,
          melwaysLanguage,
          RouteKeys.SearchResults,
          routes,
        );

        //  Since the activeJob may not be available at
        //  the time this metric is sent, we need to wait for it
        //  to be resolved first
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);
        const jobMetaData = job ? getJobMetaData(job) : null;

        return {
          action: UtagActions.View,
          value: {
            ...jobMetaData,
            ...pageMetaData,
            eventName: 'search_results_displayed',
            searchId,
            searchResultsTotal: profilesCount,
            ...getSearchResultTrackingFields(profiles),
          },
        };
      },
    },
  ],
  [RouteKeys.SimilarCandidates]: ({
    searchResultsTotal,
  }: {
    searchResultsTotal: number;
  }) => [
    {
      key: TEALIUM_KEY,
      getValue: (snapshot: RootState) => {
        const seedProfileId =
          routerSelectors.getSeedProfileIdFromQueryStringSelector(snapshot);
        const searchId = searchesSelectors.getSearchIdSelector(snapshot);

        return {
          action: UtagActions.View,
          value: {
            eventName: 'profiles_like_this_displayed',
            searchResultsTotal,
            seedProfileId,
            searchId,
          },
        };
      },
    },
  ],
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default actions;
