import { ApolloProvider } from '@apollo/client';

import useAppConfig from '../useAppConfig';
import useAuth from '../useAuth';
import useCustomHeaders from '../useCustomHeaders';
import useDatadogRum from '../useDatadogRum';

import createClient from './createClient';
import { mapGraphQLErrorLogger, mapRequestErrorLogger } from './errorMappers';

interface Props {
  children: React.ReactNode;
}

function GraphQLProvider({ children }: Props) {
  const {
    HIRER_GRAPH_URL: uri,
    APP_NAME: appName,
    ENVIRONMENT: environment,
    SITE: site,
    APP_VERSION: siteVersion,
  } = useAppConfig();
  const {
    getToken: getAuthToken,
    renewToken: renewAuthToken,
    loginWithCallback: login,
  } = useAuth();
  const getHeaders = useCustomHeaders();

  const datadogRumContext = useDatadogRum();
  const client = createClient({
    uri,
    site,
    appName,
    environment,
    siteVersion,
    login,
    getAuthToken,
    renewAuthToken,
    getHeaders,
    logError: mapRequestErrorLogger(datadogRumContext),
    logGraphQLError: mapGraphQLErrorLogger(datadogRumContext),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default GraphQLProvider;
